import React, {Component, Fragment} from 'react';
import TopNavBar from "../Components/TopNavBar";
import ImageContents from "../Components/ImageContents"
import FullScreenLoader from "../Components/FullScreenLoader";
class HomePage extends Component {
    render() {
        return (
            <Fragment>
                <TopNavBar/>
                <ImageContents/>

            </Fragment>
        );
    }
}

export default HomePage;