import React, {Component, Fragment} from 'react';
import {AiOutlineFilePdf, BiImageAdd, BsTrash} from "react-icons/all";
import cogoToast from "cogo-toast";
import axios from "axios";
import FullScreenLoader from "./FullScreenLoader";
import SweetAlert from "react-bootstrap-sweetalert";

class ImageContents extends Component {

    constructor() {
        super();
        this.state={
            ImageArray:[],
            PageSize:"A4",
            ImageFit:"Image-Top",
            PaperMargin:"",
            PaperMarginInSize:0,
            isLoading:"d-none",
            download:false,
            downloadLink:""
        }
    }

    addImage=()=>{
        document.getElementById("imageInput").click();
    }

    imageInputOnChange=(event)=>{

       let file= event.target.files[0];
       let fileName=file.name;
       let fileSize=file.size;
       let fileExtension=fileName.split('.').pop();
       let validFileExtensions=["jpg", "JPG", "jpeg", "JPEG", "PNG", "png"];
       let fileNameNew=Math.floor(100000 + Math.random() * 900000)+"_"+Math.round(new Date()/1000)+"_"+fileName

        // Maximum 5MB Image Size Validation
        if(fileSize>5242880){
            cogoToast.error("Maximum File Size 5MB", { position: "bottom-center" });
        }

        // Image File Type Validation
        else if(!validFileExtensions.includes(fileExtension)){
            cogoToast.error("Only PNG/JPG Image File Allowed", { position: "bottom-center" });
        }

        else {
         // Upload Image
            this.UploadImage(file,fileNameNew)
        }

    }

    UploadImage=(file,fileNameNew)=>{
        this.setState({isLoading:""})
        let MyFormData=new FormData
        MyFormData.append("ImageFile",file,fileNameNew)
        let config={headers: {'Content-Type': 'multipart/form-data'}}
        axios.post("/uploadFile",MyFormData,config)
            .then((res)=>{
                this.setState({isLoading:"d-none"})
                if(res.status===200 && res.data==="success"){
                    let ImageItem= {
                        ImageName:fileNameNew,
                        ImageSrc:URL.createObjectURL(file)
                    }
                    let ImageArray=this.state.ImageArray;
                    ImageArray.push(ImageItem)
                    this.setState({ImageArray:ImageArray});
                }
                else {
                    cogoToast.error("Request fail ! try again", { position: "bottom-center" });
                }
            }).catch((err)=>{
            this.setState({isLoading:"d-none"})
            cogoToast.error("Request fail ! try again", { position: "bottom-center" });
        })
    }

    // Remove Image
    removeImage=(ImageName,index)=>{
        this.setState({isLoading:""})
        axios.post("/removeFile",{ImageName:ImageName})
            .then((res)=>{
                this.setState({isLoading:"d-none"})
                if(res.status===200 && res.data==="success"){
                    let ImageArray=this.state.ImageArray;
                    ImageArray.splice(index,1)
                    this.setState({ImageArray:ImageArray});
                    cogoToast.success("Image Removed", { position: "bottom-center" });
                }
                else {
                    cogoToast.error("Request fail ! try again", { position: "bottom-center" });
                }
            }).catch((err)=>{
            this.setState({isLoading:"d-none"})
            cogoToast.error("Request fail ! try again", { position: "bottom-center" });
         })

    }

    downloadAlert=(link)=>{
      if(this.state.download===true){
          return(
              <SweetAlert
                  success
                  title="Your File Ready"
                  customButtons={
                      <React.Fragment>
                            <div className="my-3">
                                <a className="btn mx-1 btn-success" href={this.state.downloadLink} onClick={this.downloadCancel}>Download </a>
                                <button className="btn mx-1 btn-danger" onClick={this.downloadCancel}>Cancel</button>
                            </div>
                      </React.Fragment>
                  }
              >
              </SweetAlert>
          )
      }
    }

    downloadCancel=()=>{
        this.setState({download:false,downloadLink:""})
    }

    createPDF=()=>{
        let ImageArray=this.state.ImageArray
        if(ImageArray.length===0){
            cogoToast.error("No image added ! first add image", { position: "bottom-center" });
        }
        else {
            this.setState({isLoading:""})
            let PostBody={
                ImageArray:ImageArray,
                PageSize:this.state.PageSize,
                PageMargin:this.state.PaperMarginInSize,
                ImageFit:this.state.ImageFit
            }

            axios.post("/createPDF",PostBody).then((res)=>{
                if(res.status===200){
                    this.setState({isLoading:"d-none"})
                    this.setState({download:true,downloadLink:"/downloadPDF/"+res.data})
                }
                else {
                    this.setState({isLoading:"d-none"})
                    cogoToast.error("Request fail ! try again", { position: "bottom-center" });
                }
            }).catch((err)=>{
                this.setState({isLoading:"d-none"})
                cogoToast.error("Request fail ! try again", { position: "bottom-center" });
            })
        }
    }

    // Image Fit
    ImageFitOnChange=(e)=>{
        let ImageFit=e.target.value;
        if(ImageFit==="Image-Top"){
            this.setState({ImageFit:"Image-Top"})
        }
        else if(ImageFit==="Image-Center"){
            this.setState({ImageFit:"Image-Center"})
        }
        else if(ImageFit==="Image-Bottom"){
            this.setState({ImageFit:"Image-Bottom"})
        }
        else if(ImageFit==="Image-Cover"){
            this.setState({ImageFit:"Image-Cover"})
        }
        else if(ImageFit==="Image-Stretch"){
            this.setState({ImageFit:"Image-Stretch"})
        }
    }

    // PaperSize
    PaperSizeOnChange=(e)=>{
        let PaperSize=e.target.value;
        if(PaperSize==="A4"){
            this.setState({PageSize:"A4"})
        }
        else if(PaperSize==="LETTER"){
            this.setState({PageSize:"LETTER"})
        }
        else if(PaperSize==="LEGAL"){
            this.setState({PageSize:"LEGAL"})
        }
        else if(PaperSize==="TABLOID"){
            this.setState({PageSize:"TABLOID"})
        }
        else if(PaperSize==="EXECUTIVE"){
            this.setState({PageSize:"EXECUTIVE"})

        }
    }

    // Page Margin
    PaperMarginOnChange=(e)=>{
        let PaperMargin=e.target.value;
        if(PaperMargin==="No"){
            this.setState({PaperMargin:"",PaperMarginInSize:0})
        }
        else if(PaperMargin==="Normal"){
            this.setState({PaperMargin:"Normal-Margin",PaperMarginInSize:24})
        }
        else if(PaperMargin==="Narrow"){
            this.setState({PaperMargin:"Narrow-Margin",PaperMarginInSize:12})
        }
        else if(PaperMargin==="Moderate"){
            this.setState({PaperMargin:"Moderate-Margin",PaperMarginInSize:18})
        }
    }

    render() {
        let ImageListView=this.state.ImageArray.map((item,i)=>{
            return(
                <div className="col-md-4 col-lg-3 col-sm-6 p-1">
                    <div className="page-container">
                        <div className={this.state.PageSize}>
                            <img alt="img-preview" className={this.state.ImageFit + " " +this.state.PaperMargin} src={item['ImageSrc']}/>
                        </div>
                        <div className="text-center my-2">
                            <button onClick={this.removeImage.bind(this,item['ImageName'],i)} className="img-remove-btn  btn btn-danger"><BsTrash/></button>
                        </div>

                    </div>
                </div>
            )
        })

        return (
            <Fragment>
               <div className="content">
                   <div className="container">
                        <div className="row">
                            {ImageListView}
                        </div>
                   </div>
               </div>
                <div className="side-nav">
                    <input className="d-none" onChange={this.imageInputOnChange} id="imageInput" type="file"/>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-12">
                                <button onClick={this.addImage} className="btn w-100 btn-success"><BiImageAdd/> Add Image</button>
                            </div>
                            <div className="col-12 my-2">
                                <label className="form-label">Paper Size</label>
                                <select onChange={this.PaperSizeOnChange} className="form-select form-control">
                                    <option value="A4">A4</option>
                                    <option value="LETTER">Letter</option>
                                    <option value="LEGAL">Legal</option>
                                    <option value="TABLOID">Tabloid</option>
                                    <option value="EXECUTIVE">Executive </option>
                                </select>
                            </div>
                            <div className="col-12 my-2">
                                <label className="form-label">Margin Size</label>
                                <select onChange={this.PaperMarginOnChange} className="form-select form-control">
                                    <option value="No">No Margin</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Narrow">Narrow</option>
                                    <option value="Moderate">Moderate</option>
                                </select>
                            </div>
                            <div className="col-12 my-2">
                                <label className="form-label">Image Fit</label>
                                <select onClick={this.ImageFitOnChange} className="form-select form-control">
                                    <option value="Image-Top">Image Top</option>
                                    <option value="Image-Center">Image Center</option>
                                    <option value="Image-Bottom">Image Bottom</option>
                                    <option value="Image-Cover">Image Cover</option>
                                    <option value="Image-Stretch">Image Stretch</option>
                                </select>
                            </div>
                            <div className="col-12 my-2">
                                <button onClick={this.createPDF} className="btn w-100 btn-success"><AiOutlineFilePdf/> Create PDF</button>
                            </div>
                        </div>
                    </div>
                </div>
                <FullScreenLoader isLoading={this.state.isLoading}/>
                {this.downloadAlert()}
            </Fragment>
        );
    }
}
export default ImageContents;